<template>
  <v-container fluid>
    <v-card tile>

      <v-card-title>
        My Devices
        <v-spacer></v-spacer>
<!--        <v-text-field-->
<!--          dense-->
<!--          v-model="search"-->
<!--          append-icon="mdi-magnify"-->
<!--          label="Search"-->
<!--          single-line-->
<!--          hide-details-->
<!--        ></v-text-field>-->
<!--        <v-divider class="mx-4" inset vertical></v-divider>-->
        <v-btn @click="openNewDeviceDialog" class="primary">New item</v-btn>
      </v-card-title>

      <v-data-table
        :headers="headers"
        :items="devices"
        :search="search"
        :loading="loading"
        loading-text="Loading... Please wait"
      >
        <template v-slot:item.active="{ item }">
          <v-chip
            :color="item.active ? 'green' : 'red'"
            dark
            filter
            link
            class="mr-1"
          >
            {{ item.active ? 'Yes' : 'No' }}
          </v-chip>
        </template>

        <template v-slot:item.actions="{ item }">
          <v-icon
            class="mr-2"
            color="blue"
            @click="openEditDeviceDialog(item)"
          >
            mdi-pencil
          </v-icon>
          <v-icon
            color="red"
            @click="openDeleteDeviceDialog(item)"
          >
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
    </v-card>

    <v-dialog
      v-model="newDeviceDialog"
      persistent
      max-width="600px"
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-card-title>
          <span class="headline">New device</span>
        </v-card-title>
        <v-card-text>
          <v-form v-model="isValidNewDeviceForm">
            <v-text-field
              v-model="device.name"
              label="Name"
              :rules="[rules.required]"
            ></v-text-field>
            <v-text-field
              v-model="device.macAddress"
              label="MAC Address"
              hint="MAC Address format: XX:XX:XX:XX:XX:XX or XX-XX-XX-XX-XX-XX"
              :rules="[rules.required, rules.valid_mac_address]"
            ></v-text-field>
            <v-select
              v-model="device.type"
              label="Type"
              :items="types"
              :rules="[rules.required]"
            ></v-select>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="newDeviceDialog = false">Close</v-btn>
          <v-btn color="blue darken-1" text @click="createUserDevice" :disabled="!isValidNewDeviceForm">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="editDeviceDialog"
      persistent
      max-width="600px"
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Edit device</span>
        </v-card-title>
        <v-card-text>
          <v-form v-model="isValidEditDeviceForm">
            <v-text-field
              v-model="device.name"
              label="Name"
              :rules="[rules.required]"
            ></v-text-field>
            <v-text-field
              v-model="device.macAddress"
              label="MAC Address"
              :rules="[rules.required, rules.valid_mac_address]"
            ></v-text-field>
            <v-select
              v-model="device.type"
              label="Type"
              :items="types"
              :rules="[rules.required]"
            ></v-select>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="editDeviceDialog = false">Close</v-btn>
          <v-btn color="blue darken-1" text @click="editUserDevice" :disabled="!isValidEditDeviceForm">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="deleteDeviceDialog"
      max-width="500px"
      transition="dialog-bottom-transition"
    >
      <v-card tile>
        <v-card-title class="headline">Are you sure you want to delete this item?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="deleteDeviceDialog = false">Cancel</v-btn>
          <v-btn color="red darken-1" text @click="deleteUserDevice">Yes</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>

</template>

<script>
export default {
  data () {
    return {
      path: 'devices',
      devices: [],
      device: Object(),
      types: ['Wi-Fi connection', 'Cable connection'],
      newDeviceDialog: false,
      editDeviceDialog: false,
      deleteDeviceDialog: false,
      loading: true,
      search: '',
      headers: [
        { text: 'NAME', value: 'name' },
        { text: 'MAC ADDRESS', value: 'macAddress' },
        { text: 'TYPE', value: 'type' },
        { text: 'ACTIVE', value: 'active' },
        { text: 'ACTIONS', value: 'actions', sortable: false, width: '90px' }
      ],
      isValidEditDeviceForm: false,
      isValidNewDeviceForm: false,
      rules: {
        required: value => !!value || 'Required field',
        valid_mac_address: v => /^[0-9a-f]{2}([.:-])[0-9a-f]{1,2}(?:\1[0-9a-f]{2}){4}$/i.test(v) || 'Invalid MAC address'
      }
    }
  },

  methods: {
    formatMacAddress (macAddressString) {
      return macAddressString.slice(0, 2) + ':' +
        macAddressString.slice(2, 4) + ':' +
        macAddressString.slice(4, 6) + ':' +
        macAddressString.slice(6, 8) + ':' +
        macAddressString.slice(8, 10) + ':' +
        macAddressString.slice(10, 12)
    },

    openNewDeviceDialog () {
      this.device = Object()
      this.newDeviceDialog = true
    },

    openEditDeviceDialog (item) {
      this.device = Object()
      Object.assign(this.device, item)
      this.editDeviceDialog = true
    },

    openDeleteDeviceDialog (item) {
      this.device = item
      this.deleteDeviceDialog = true
    },

    async fetchUserDevices () {
      this.devices = await this.$store.dispatch('account/fetchUserDevices')
      for (let i = 0; i < this.devices.length; i++) {
        this.devices[i].macAddress = this.formatMacAddress(this.devices[i].macAddress)
      }
    },

    async createUserDevice () {
      this.device.macAddress = this.device.macAddress
        .replaceAll(':', '')
        .replaceAll('-', '')
        .replaceAll('.', '')
      await this.$store.dispatch('account/createUserDevice', this.device)
      this.newDeviceDialog = false
      await this.fetchUserDevices()
      this.device = Object()
    },

    async editUserDevice () {
      this.device.macAddress = this.device.macAddress
        .replaceAll(':', '')
        .replaceAll('-', '')
        .replaceAll('.', '')
      await this.$store.dispatch('account/updateUserDevice', this.device)
      this.editDeviceDialog = false
      await this.fetchUserDevices()
      this.device = Object()
    },

    async deleteUserDevice () {
      await this.$store.dispatch('account/deleteUserDevice', this.device.id)
      this.deleteDeviceDialog = false
      await this.fetchUserDevices()
      this.device = Object()
    }
  },

  async mounted () {
    document.title = 'Devices | Masarka Student Club'
    this.loading = true
    await this.fetchUserDevices()
    this.loading = false
  }
}
</script>

<style scoped>

</style>
